'use client';

import LayoutContent from '@/shared/components/app/Layout';
import { Progress } from '@/shared/shadcn/ui/progress';
import {
  Check,
  ChevronRight,
  CornerUpLeft,
  FilePenLine,
  FileUp,
  SquarePen,
} from 'lucide-react';
import React from 'react';

import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from 'recharts';
import { ChartConfig, ChartContainer } from '@/shared/shadcn/ui/chart';
import Image from 'next/image';

const chartData = [{ browser: 'safari', visitors: 65, fill: 'red' }];
const chartConfig = {
  visitors: {
    label: 'Visitors',
  },
  safari: {
    label: 'Safari',
    color: 'yellow',
  },
} satisfies ChartConfig;

const shortcuts = [
  {
    label: 'Upload Document',
    icon: <FileUp />,
    onclick: () => alert('Upload Document'),
    color: '#8787F5',
    bg: '#E4E4FD',
  },
  {
    label: 'Request Signature',
    icon: <FilePenLine />,
    onclick: () => alert('Request Signature'),
    color: '#F17203',
    bg: '#FFF8E9',
  },
  {
    label: 'Templates',
    icon: <SquarePen />,
    onclick: () => alert('Templates'),
    color: '#12B76A',
    bg: '#D7F7E8',
  },
];

const RecentActivity = [
  {
    text: () => (
      <>
        Document <span className='text-[#2482AD]'>Laporan Bulanan</span>{' '}
        berhasil dibagikan.
      </>
    ),
    time: '2 hours ago',
    type: 'share',
  },
  {
    text: () => (
      <>
        Document Surat{' '}
        <span className='text-[#2482AD]'>Pernyataan berhasil</span> diunggah.
      </>
    ),
    time: '1 week ago',
    type: 'success',
  },
];

const ProgressData = [
  {
    label: 'Storage Capacity',
    value: 55,
    color: '#F79009',
  },
  {
    label: 'File Capacity',
    value: 71,
    color: '#7B8AF2',
  },
];

const AssignmentOverview = [
  {
    label: '5 documents awaiting review',
    bg: 'bg-[#5AA4FF]',
    bgSecond: 'bg-[#79B9F5]',
    people: [
      'https://randomuser.me/api/portraits/women/1.jpg',
      'https://randomuser.me/api/portraits/women/2.jpg',
      'https://randomuser.me/api/portraits/women/3.jpg',
    ],
  },
  {
    label: '4 signers in progres',
    bg: 'bg-[#B2A2FD]',
    bgSecond: 'bg-[#D4BBFD]',
    people: [
      'https://randomuser.me/api/portraits/women/4.jpg',
      'https://randomuser.me/api/portraits/women/5.jpg',
      'https://randomuser.me/api/portraits/women/6.jpg',
    ],
  },
  {
    label: '8 assignments completed',
    bg: 'bg-[#F17203]',
    bgSecond: 'bg-[#F0B86A]',
    people: [
      'https://randomuser.me/api/portraits/women/7.jpg',
      'https://randomuser.me/api/portraits/women/8.jpg',
      'https://randomuser.me/api/portraits/women/9.jpg',
    ],
  },
];

const AssignmentOverviewContent = () => {
  return (
    <>
      <div className='font-bold mb-2'>Assignment Overview</div>
      <div className='text-sm text-gray-500'>Track your document progress</div>

      <div className='grid grid-cols-1 md:grid-cols-2 mt-6 gap-5'>
        {AssignmentOverview.map((item, index) => (
          <div
            className={`col-span-1 w-full ${item.bg} rounded-xl`}
            key={index}
          >
            <div className='flex py-6 px-4'>
              {item.people.map((person, index) => (
                <Image
                  src={person}
                  alt='Profile'
                  width={32}
                  height={32}
                  className={`rounded-full outline outline-1 outline-white ${
                    index === 0 ? '' : '-ml-2'
                  }`}
                />
              ))}
            </div>

            <div
              className={`flex items-center justify-between ${item.bgSecond} rounded-xl px-4 py-2`}
            >
              <div className='text-white font-light'>{item.label}</div>

              <div className={`p-3 rounded-xl ${item.bg}`}>
                <ChevronRight size={16} className='text-white' />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default function Dashboard() {
  return (
    <LayoutContent
      title={{
        text: 'Dashboard',
        tooltip: { status: true, text: 'Dashboard' },
      }}
    >
      <div className='grid grid-cols-1 lg:grid-cols-4 lg:gap-10'>
        <div className='col-span-1 lg:col-span-3'>
          <div className='flex gap-4 mb-8 min-w-full overflow-x-auto'>
            {shortcuts.map((shortcut, index) => (
              <div
                key={index}
                className='flex items-center border-[1px] border-gray-300 gap-3 py-4 px-7 cursor-pointer rounded-xl'
                onClick={shortcut.onclick}
              >
                <div
                  style={{
                    backgroundColor: shortcut.bg,
                    padding: '0.5rem',
                    borderRadius: '50%',
                  }}
                >
                  {React.cloneElement(shortcut.icon, {
                    size: 20,
                    color: shortcut.color,
                  })}
                </div>
                <span className='font-semibold'>{shortcut.label}</span>
              </div>
            ))}
          </div>

          <div className='hidden lg:block'>
            <AssignmentOverviewContent />
          </div>
        </div>

        <div className='grid grid-cols-2 lg:grid-cols-1'>
          <div className='col-span-2 md:col-span-1'>
            <div className='flex items-center md:flex-col'>
              <ChartContainer config={chartConfig} className='aspect-square'>
                <RadialBarChart
                  data={chartData}
                  startAngle={90}
                  endAngle={360}
                  innerRadius={80}
                  outerRadius={110}
                >
                  <PolarGrid
                    gridType='circle'
                    radialLines={false}
                    stroke='none'
                    className='first:fill-whie'
                    polarRadius={[86, 74]}
                  />
                  <RadialBar dataKey='visitors' background cornerRadius={10} />
                  <PolarRadiusAxis
                    tick={false}
                    tickLine={false}
                    axisLine={false}
                  >
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor='middle'
                              dominantBaseline='middle'
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className='fill-foreground text-4xl font-bold'
                              >
                                {chartData[0].visitors.toLocaleString()}
                              </tspan>
                              <tspan
                                x={viewBox.cx}
                                y={(viewBox.cy || 0) + 24}
                                className='fill-muted-foreground'
                              >
                                Visitors
                              </tspan>
                            </text>
                          );
                        }
                      }}
                    />
                  </PolarRadiusAxis>
                </RadialBarChart>
              </ChartContainer>

              <div>
                {ProgressData.map((data, index) => (
                  <div className='mb-3' key={index}>
                    <div className='flex justify-between mb-2'>
                      <div className='font-bold'>{data.label}</div>
                      <div>{data.value}%</div>
                    </div>
                    <Progress value={data.value} className='h-2' style={{}} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='hidden md:flex flex-col col-span-1'>
            <div className='font-bold my-5'>Recent Activity</div>

            {RecentActivity.map((activity, index) => (
              <div key={index} className='flex gap-3 mt-3'>
                <div>
                  <div
                    className={`h-10 w-10 rounded-full flex justify-center items-center ${
                      activity.type === 'share'
                        ? 'bg-[#AE9FFC]'
                        : 'bg-[#58A2FF]'
                    }`}
                  >
                    {activity.type === 'share' ? (
                      <Check size={20} color='#fff' />
                    ) : (
                      <FilePenLine size={20} color='#fff' />
                    )}
                  </div>
                </div>

                <div>
                  <div className='text-sm line-clamp-2'>{activity.text()}</div>
                  <div className='text-xs text-gray-500'>{activity.time}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='block lg:hidden'>
        <AssignmentOverviewContent />
      </div>
    </LayoutContent>
  );
}
